<template>
    <v-contaner class="d-flex justify-center mt-12">
        <div class="continer-contant">
            <v-row class="mt-3" data-aos="fade-down" >
                <v-col cols="12">
                    <div class="d-flex justify-center" >
                        <img class="icon-we" src="../../assets/about-us.svg" alt="">
                    </div>
                </v-col>
                <v-col cols="12" class="pa-0">
                    <div class="d-flex justify-center">
                        <img class="icon-stars-down" src="../../assets/chart-icon.svg" alt="">
                    </div>
                </v-col>
            </v-row>
            <v-row class="d-flex justify-center ">
                <div class="continer-contant doubi-photo-contant-header mt-12" >
                    <div class="d-flex justify-center" data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="750">
                        <img class="doubi-photo" src="../../assets/image-one-company.svg" alt="">
                    </div>
                    <div class="d-flex justify-center doubi-photo">
                        <div class="text-end d-flex align-self-center " data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="750">
                            <span class="contant-text-sup-title "><span class="contant-text-title">شركة ون التجارية
                                </span>متخصصة في التجارة العامة، وهذا يعني أنها تشمل مجموعة واسعة من الأنشطة التجارية دون تخصص محدد في صناعة معينة. قد تتضمن أنشطتها تجارة المواد الغذائية، والمنتجات الاستهلاكية، والأجهزة الإلكترونية، ومواد البناء، والمنتجات الصناعية و الذهب و الحديد و النفط و الديزل و قطع غيار السفن و القوارب و مكوناتها، وغيرها. تهدف الشركة إلى تلبية احتياجات العملاء في مختلف القطاعات عبر توفير منتجات ذات جودة عالية وخدمة ممتازة. تعتمد شركة ون على شبكة واسعة من الموردين والشركاء التجاريين لتحقيق نجاحها في السوق العام.</span>
                            <img class="px-2 line-icon" src="../../assets/line-contant.svg" alt="">
                        </div>
                    </div>
                </div>
            </v-row>
        </div>
    </v-contaner>
</template>

<script>

    export default {
        components: {
        },

        data: () => ({
            //
        }),
    }
</script>
<style>
    .continer-contant {
        width: calc(277px + (1216.25 - 277) * ((100vw - 480px) / (1440 - 480)));

        .icon-we {
            width: calc(249px + (552 - 249) * ((100vw - 480px) / (1440 - 480)));
        }

        .icon-stars-down {
            width: calc(57px + (120.98 - 57) * ((100vw - 480px) / (1440 - 480)));
            height: calc(50px + (85.31 - 50) * ((100vw - 480px) / (1440 - 480)));
        }

        .doubi-photo {
            width: calc(277px + (584 - 277) * ((100vw - 480px) / (1440 - 480)));
            height: calc(187.13px + (356.24 - 187.13) * ((100vw - 480px) / (1440 - 480)));
        }

        .contant-text-title,
        .contant-text-sup-title {
            font-family: "DIN Next LT Arabic Light";
            font-size: calc(14px + (20 - 14) * ((100vw - 480px) / (1440 - 480)));
            font-weight: calc(700 + (400 - 700) * ((100vw - 480px) / (1440 - 480)));
            line-height: calc(20.58px + (29.4 - 20.58px) * ((100vw - 480px) / (1440 - 480)));
            text-align: justified;
        }

        .contant-text-title {
            color: #CAAD5B;
        }

        .contant-text-sup-title {
            color: #13284C;
        }


        .doubi-photo-contant-header {
            width: auto;
            height: auto;
            display: flex;
        }


        .line-icon {
            height: 28vh;
        }
    }

    @media (max-width: 960px) {

        .doubi-photo-contant-header {
            display: block !important;
        }

        .doubi-photo {
            margin-top: 10px;
            height: auto !important;

        }

        .line-icon {
            display: none;
        }
    }
</style>