<template>
    <v-contaner class="d-flex justify-center mt-12">
        <div class="continer-contant" >
            <v-row class="mt-3" data-aos="fade-down" >
                <v-col cols="12" >
                    <div class="d-flex justify-center">
                        <img class="icon-we" src="../../assets/achievement.svg" alt="">
                    </div>
                </v-col>
                <v-col cols="12" class="pa-0" >
                    <div class="d-flex justify-center">
                        <img class="icon-stars-down" src="../../assets/power.svg" alt="">
                    </div>
                </v-col>
            </v-row>
            <v-row class="d-flex justify-space-around  ">
                <div data-aos="zoom-in">
                    <div class="d-flex justify-center " data-aos="fade-down" data-aos-easing="linear" data-aos-duration="750">
                        <img class="ok-icon" src="../../assets/ok-icon.svg" alt="">
                    </div>
                    <div class="mt-4" data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="1500">
                        <img class="more-10000" src="../../assets/more-10000.svg" alt="">
                    </div>
                </div>
                <div data-aos="zoom-in">
                    <div class="d-flex justify-center " data-aos="fade-down" data-aos-easing="linear" data-aos-duration="750">
                        <img class="up-power-icon" src="../../assets/up-power-icon.svg" alt="">
                    </div>
                    <div class="mt-4" data-aos="zoom-in" data-aos-easing="linear"  data-aos-duration="1500">
                        <img class="more-3500" src="../../assets/more-3500.svg" alt="">
                    </div>
                </div>
            </v-row>
            <v-row class="d-flex justify-center mt-10 ">
                <div data-aos="zoom-in">
                    <div class="d-flex justify-center">
                        <img class="one-icon" src="../../assets/one.svg" alt="">
                    </div>
                </div>
            </v-row>
            <v-row class="d-flex justify-center " data-aos="zoom-in">
                    <div class="d-flex justify-end mt-3">
                        <div class="text-end d-flex align-self-center ">
                            <span class="contant-text-sup-title "><span class="contant-text-title">شركة ون التجارية
                            </span>تعكس شركة ون التجارية للتجارة العامة التزامها الراسخ بالجودة والمصداقية وخدمة العملاء. بفضل فريقها المحترف وشبكتها الواسعة من الشركاء التجاريين، تسعى الشركة دائمًا لتلبية احتياجات السوق وتحقيق النجاح المستدام. من خلال تقديم منتجات عالية الجودة وخدمات ممتازة، تهدف شركة ون لبناء علاقات قوية ومستدامة مع عملائها والمجتمع المحيط به</span>
                    </div>
                </div>
            </v-row>
        </div>
    </v-contaner>
</template>

<script>

export default {
    components: {
    },

    data: () => ({
        //
    }),
}
</script>
<style>
.continer-contant {
    width: calc(277px + (1216.25 - 277) * ((100vw - 480px) / (1440 - 480)));

    .icon-we {
        width: calc(249px + (552 - 249) * ((100vw - 480px) / (1440 - 480)));
    }

    .icon-stars-down {
        width: calc(70px + (150.98 - 57) * ((100vw - 480px) / (1440 - 480)));
        height: calc(60px + (105.31 - 50) * ((100vw - 480px) / (1440 - 480)));
    }

    .ok-icon,
    .up-power-icon{
        width: calc(55px + (120 - 55) * ((100vw - 480px) / (1440 - 480)));
        height: calc(60px + (122 - 60) * ((100vw - 480px) / (1440 - 480)));
    }
    .more-10000,
    .more-3500{
        width: calc(109px + (200 - 109) * ((100vw - 480px) / (1440 - 480)));
        height: calc(74px + (165 - 74) * ((100vw - 480px) / (1440 - 480)));
    }
    .one-icon{

        width: calc(50.34px + (125 - 50.34) * ((100vw - 480px) / (1440 - 480)));
        height: calc(105.79px + (225 - 105.79) * ((100vw - 480px) / (1440 - 480)));
    }


    .contant-text-title,
    .contant-text-sup-title {
        font-family: "DIN Next LT Arabic Light";
        font-size: calc(14px + (20 - 14) * ((100vw - 480px) / (1440 - 480)));
        font-weight: calc(700 + (400 - 700) * ((100vw - 480px) / (1440 - 480)));
        line-height: calc(20.58px + (29.4 - 20.58px) * ((100vw - 480px) / (1440 - 480)));
        text-align: justified;
    }

    .contant-text-title {
        color: #CAAD5B;
    }

    .contant-text-sup-title {
        color: #13284C;
    }

}

</style>