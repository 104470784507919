<template>
  <v-app>
    <HeaderComponent />
    <v-main>
      <HeroSection/>
      <SectionUs/>
      <SectionAboutUs/>
      <SectionAchievements/>
      <SectionDownloadFile/>
    </v-main>
    <FooterComponent/>
  </v-app>
</template>

<script>
import HeaderComponent from './components/layout/HeaderComponent.vue'
import FooterComponent from './components/layout/FooterComponent.vue'
import HeroSection from './components/sections/HeroSection.vue'
import SectionUs from './components/sections/SectionUs.vue'
import SectionAboutUs from './components/sections/SectionAboutUs.vue'
import SectionAchievements from './components/sections/SectionAchievements.vue'
import SectionDownloadFile from './components/sections/SectionDownloadFile.vue'
export default {
  name: 'App',

  components: {
    HeaderComponent,
    HeroSection,
    SectionUs,
    FooterComponent,
    SectionAboutUs,
    SectionAchievements,
    SectionDownloadFile
  },

  data: () => ({
    //
  }),

}
</script>
<style>
  @import url('https://db.onlinewebfonts.com/c/d78f5d2c76185fa07aaf8dd729eef33e?family=DIN+Next+LT+Arabic+Light'); 
  @font-face {
    font-family: "DIN Next LT Arabic Light";
    src: url("https://db.onlinewebfonts.com/t/d78f5d2c76185fa07aaf8dd729eef33e.eot");
    src: url("https://db.onlinewebfonts.com/t/d78f5d2c76185fa07aaf8dd729eef33e.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/d78f5d2c76185fa07aaf8dd729eef33e.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/d78f5d2c76185fa07aaf8dd729eef33e.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/d78f5d2c76185fa07aaf8dd729eef33e.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/d78f5d2c76185fa07aaf8dd729eef33e.svg#DIN Next LT Arabic Light")format("svg");
  }
@import './app.css';
</style>