<template>
    <v-contaner class="d-flex justify-center">
        <div class="continer-contant" >
            <v-row class="my-6 " data-aos="fade-down" >
                <v-col cols="12" >
                    <div class="d-flex justify-center" >
                        <img class="icon-we" src="../../assets/we.svg" alt="">
                    </div>
                </v-col>
                <v-col cols="12" class="pa-0">
                    <div class="d-flex justify-center">
                        <img class="icon-stars-down" src="../../assets/downStart.svg" alt="">
                    </div>
                </v-col>
            </v-row>
            <v-row class="d-flex justify-center ">
                <div class="continer-contant doubi-photo-contant-header mt-6">
                    <div class="d-flex justify-center" data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="750">
                        <img class="doubi-photo" src="../../assets/doubiPhoto.svg" alt="">
                    </div>
                    <div class="d-flex justify-center doubi-photo" data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="750">
                        <div class="text-end d-flex align-self-center ">
                            <span  class="contant-text-sup-title "><span class="contant-text-title">ون التجارية </span>شركة إماراتية, وهي جزء من مجموعة ون تأسست في إمارة دبي بدولة الامارات العربية المتحدة وتمارس أعمالها بنجاح تام منذ تأسيسها وتقوم المؤسسة بالعمل على العديد من الخدمات مما أكسبها الخبرة و السمعة الطيبة التي أدت لنجاح المؤسسة تقدم ون التجارية مجموعة منوعة من الخدمات أهمها</span>
                            <img class="px-2 line-icon" src="../../assets/line-contant.svg" alt="">
                        </div>
                    </div>
                </div>
            </v-row>
            <v-row class="d-flex justify-center mt-8">
                <div  class="pa-0 " data-aos="fade-up" data-aos-easing="linear" data-aos-duration="750">
                    <div class="d-flex justify-center px-1">
                        <img class="crcyl-icon" src="../../assets/Group-0.svg" alt="">
                    </div>
                </div>
                <div  class="pa-0 " data-aos="fade-down" data-aos-easing="linear" data-aos-duration="750">
                    <div class="d-flex justify-center px-1">
                        <img class="crcyl-icon" src="../../assets/Group-1.svg" alt="">
                    </div>
                </div>
                <div  class="pa-0 " data-aos="fade-up" data-aos-easing="linear" data-aos-duration="750">
                    <div class="d-flex justify-center px-1">
                        <img class="crcyl-icon" src="../../assets/Group-2.svg" alt="">
                    </div>
                </div>
                <div  class="pa-0 " data-aos="fade-down" data-aos-easing="linear" data-aos-duration="750">
                    <div class="d-flex justify-center px-1">
                        <img class="crcyl-icon" src="../../assets/Group-3.svg" alt="">
                    </div>
                </div>
                <div  class="pa-0 " data-aos="fade-up" data-aos-easing="linear" data-aos-duration="750">
                    <div class="d-flex justify-center px-1">
                        <img class="crcyl-icon" src="../../assets/Group-4.svg" alt="">
                    </div>
                </div>
                <div  class="pa-0 " data-aos="fade-down" data-aos-easing="linear" data-aos-duration="750">
                    <div class="d-flex justify-center px-1">
                        <img class="crcyl-icon" src="../../assets/Group-5.svg" alt="">
                    </div>
                </div>
            </v-row>
            <v-row class="mt-8">
                <v-col  data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="750">
                    <div class="d-flex justify-center">
                        <v-btn class="btn-group">
                            تجارة عامة
                        </v-btn>
                    </div>
                </v-col>
                <v-col  data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="750">
                    <div class="d-flex justify-center">
                        <v-btn class="btn-group">
                            تجارة الذهب
                        </v-btn>
                    </div>
                </v-col>
                <v-col  data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="750">
                    <div class="d-flex justify-center">
                        <v-btn class="btn-group">
                            تجارة الحديد
                        </v-btn>
                    </div>
                </v-col>
                <v-col  data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="750">
                    <div class="d-flex justify-center">
                        <v-btn class="btn-group">
                            تجارة الالكترونيات
                        </v-btn>
                    </div>
                </v-col>
                <v-col  data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="750">
                    <div class="d-flex justify-center">
                        <v-btn class="btn-group">
                            تجارة المواد الغذائية 
                        </v-btn>
                    </div>
                </v-col>
                <v-col  data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="750">
                    <div class="d-flex justify-center">
                        <v-btn class="btn-group">
                            تجارة قطع غيار السفن و القوارب و مكوناتها
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </div>
    </v-contaner>
</template>

<script>

export default {
    components: {
    },

    data: () => ({
        //
    }),
}
</script>
<style>

.continer-contant{
        width: calc(277px + (1216.25 - 277) * ((100vw - 480px) / (1440 - 480)));
        .icon-we{
            width: calc(249px + (552 - 249) * ((100vw - 480px) / (1440 - 480)));
        }
        .icon-stars-down{
            width: calc(57px + (120.98 - 57) * ((100vw - 480px) / (1440 - 480)));
            height: calc(50px + (85.31 - 50) * ((100vw - 480px) / (1440 - 480)));
        }
        .doubi-photo{
            width: calc(277px + (584 - 277) * ((100vw - 480px) / (1440 - 480)));
            height: calc(187.13px + (356.24 - 187.13) * ((100vw - 480px) / (1440 - 480)));
        }
        
        .contant-text-title , .contant-text-sup-title{
            font-family: "DIN Next LT Arabic Light";
            font-size: calc(14px + (20 - 14) * ((100vw - 480px) / (1440 - 480)));
            font-weight: calc(700 + (400 - 700) * ((100vw - 480px) / (1440 - 480)));
            line-height: calc(20.58px + (29.4 - 20.58px) * ((100vw - 480px) / (1440 - 480)));
            text-align: justified;
        }
        .contant-text-title{
            color: #CAAD5B;
        }
        .contant-text-sup-title{
            color: #13284C;
        }
        .crcyl-icon{
            width: calc(34.46px + (81 - 34.46) * ((100vw - 480px) / (1440 - 480)));
            height: calc(44.43px + (85.14 - 44.43) * ((100vw - 480px) / (1440 - 480)));
        }
        .doubi-photo-contant-header{
            width: auto ;
            height: auto;
            display: flex ;
        }
        .doubi-photo-contant-site{
            width: calc(277px + (584 - 277) * ((100vw - 480px) / (1440 - 480)));
            height: calc(187.13px + (356.24 - 187.13) * ((100vw - 480px) / (1440 - 480)));
        }
        .btn-group{
            padding: 1px 40px  7px 40px !important;
            background: #EFEFF0;
            box-shadow: 0px 4px 14.7px 0px #00000040;
            color: #13284C;
            font-family: "DIN Next LT Arabic Light";
            border-radius: 20px;
            font-size: calc(14px + (26 - 14) * ((100vw - 480px) / (1440 - 480)));
            font-weight: calc(400 + (500 - 400) * ((100vw - 480px) / (1440 - 480)));
            line-height: calc(20.58px + (52.92 - 20.58px) * ((100vw - 480px) / (1440 - 480)));
            text-align: justified;
            height: fit-content !important;
            width: fit-content !important;
        }
        .line-icon{
            height: 18vh;
        }
    }

    @media (max-width: 960px){

        .doubi-photo-contant-header{
            display: block !important;
        }
        .doubi-photo{
            margin-top: 10px;
            height: auto !important;

        }
        .line-icon{
            display: none;
        }
    }
</style>