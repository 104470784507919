<template>
    <div  class="bg-basic">
        <div class="d-flex justify-center ">
            <div >
                <img class="social-icon" src="../../assets/social-icons/social-fa.svg" alt="">
            </div>
            <div >
            <img class="social-icon" src="../../assets/social-icons/social-in.svg" alt="">
        </div>
            <div >
            <img class="social-icon" src="../../assets/social-icons/social-linkedin.svg" alt="">
        </div>
            <div >
            <img style="margin: 0 !important;" class="social-icon" src="../../assets/social-icons/social-wa.svg" alt="">
        </div>
            
        </div>
        <div class="d-flex justify-center ">
            <div class="justify-space-between d-flex continer-contant">
                <div >
                    <p class="t-footer" >Legal Terms & Conditions </p>
                    <p class="t-footer" >One Trading </p>
                    <p class="t-footer" >Terms and Conditions </p>
                    <p class="t-footer" >privacy policy </p>
                    <p class="t-footer" >Protection Guidelines</p>
                    <p class="t-footer" >Site Map</p>
                    <p class="t-footer" >Cookie Notice </p>
                </div>
                <div class=" text-end" >
                    <p class="t-footer" >الشروط والأحكام القانونية </p>
                    <p class="t-footer" >ون التجارية  </p>
                    <p class="t-footer" >الأحكام والشروط </p>
                    <p class="t-footer" >سياسة الخصوصية</p>
                    <p class="t-footer" >إرشادات الحماية</p>
                    <p class="t-footer" >خريطة الموقع </p>
                    <p class="t-footer" >اشعار بملفات تعريف الارتباط</p>
                </div>    
            </div>
            
        </div>
        <div class="d-flex justify-center ">
                <img class="contact-icon" src="../../assets/contact-icon.svg" alt="">
        </div>
        <div class="d-flex justify-center ">
            <div class="dev-co">
                تصميم وتطوير كود ون
            </div>
        </div>
    </div>
 </template>
  <script>
  
export default {
components: {
},

data: () => ({
}),
created(){
}
}
</script>
<style >
.continer-contant {
        width: calc(277px + (1216.25 - 277) * ((100vw - 480px) / (1440 - 480)));
        padding: 0px calc(18px + (165 - 18) * ((100vw - 480px) / (1440 - 480)));
;
}
.social-icon-co{
    margin: calc(1px + (10 - 1) * ((100vw - 480px) / (1440 - 480))) ;
}
.social-icon{
  width: calc(25px + (65 - 25) * ((100vw - 480px) / (1440 - 480))) ;
  height: calc(24px + (55 - 24) * ((100vw - 480px) / (1440 - 480))) ;
  margin-right: calc(10px + (75 - 10) * ((100vw - 480px) / (1440 - 480))) ;

}
.bg-basic{
    background: #13284C;
    height: fit-content;
    padding-top:calc(8px + (27 - 8) * ((100vw - 480px) / (1440 - 480))) ;
    padding-bottom:calc(8px + (27 - 8) * ((100vw - 480px) / (1440 - 480))) ;

}
.t-footer{
    font-family: "DIN Next LT Arabic Light";
    font-size: calc(7px + (20 - 7) * ((100vw - 480px) / (1440 - 480)));
    line-height: calc(9.82px + (27.4 - 9.82) * ((100vw - 480px) / (1440 - 480)));
    font-weight: 300;

    color: white;
    font-weight: 300;
}
.contact-icon{
 
width: calc(240.6px + (919 - 240.6) * ((100vw - 480px) / (1440 - 480)));
height: calc(11.64px + (44.45 - 11.64) * ((100vw - 480px) / (1440 - 480)));

}
.dev-co{
 
font-weight: 300;
text-align: justified;
font-family: "DIN Next LT Arabic Light";
font-size: calc(5px + (20 - 5) * ((100vw - 480px) / (1440 - 480)));
line-height: calc(7.35px + (29.4 - 7.35) * ((100vw - 480px) / (1440 - 480)));
color: #C8AC5B;

}

</style>