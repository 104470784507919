<template>
    <v-contaner class="d-flex justify-center my-12">
        <div class="continer-contant">
            <v-row class=" d-flex justify-center">
                <div class=" my-3  d-flex justify-center">
                        <img  class="hr-line" src="../../assets/hr-line-icon.svg" alt="">
                    </div>
            </v-row>
            <v-row class="d-flex justify-center">
                <div class=" contant">
                    لتحميل ملف الشركة
                </div>
            </v-row>
            <v-row class=" d-flex justify-center">
                <div class="mt-1" data-aos="flip-right">
                    <button class="btn-download">
                        إضغط هنا
                    </button>
                </div>
            </v-row>
        </div>
    </v-contaner>
</template>

<script>

    export default {
        components: {
        },

        data: () => ({
            //
        }),
    }
</script>
<style>
    .continer-contant {
        width: calc(277px + (1216.25 - 277) * ((100vw - 480px) / (1440 - 480)));
        
        .hr-line{
            width: calc(180px + (573 - 180) * ((100vw - 480px) / (1440 - 480)));
            height: calc(5px + (20 - 5) * ((100vw - 480px) / (1440 - 480)));
        }
        .contant{
            font-family: "DIN Next LT Arabic Light";
            color: #000000;
            font-size: calc(12px + (24 - 12) * ((100vw - 480px) / (1440 - 480)));
            font-weight: calc(300 + (400 - 300) * ((100vw - 480px) / (1440 - 480)));
            line-height: calc(17.64px + (50 - 17.64) * ((100vw - 480px) / (1440 - 480)));
            
        }
        .btn-download{
            padding: 1px 0px 7px 0px;
            font-size: calc(20px + (30 - 20) * ((100vw - 480px) / (1440 - 480)));
            width: calc(162px + (278 - 162) * ((100vw - 480px) / (1440 - 480)));
            height: calc(31px + (56 - 31) * ((100vw - 480px) / (1440 - 480)));
            border-radius: 20px ;
            font-family: "DIN Next LT Arabic Light";
            font-weight: 700;
            line-height: 29.4px;
            text-align: justified;
            background-color: #C8AC5B;
            color: #EFEFF0;



        }
        
    }
    @media (max-width: 480px) { /* Mobile */
        .btn-download{
            padding: 1px 0px 37px 0px !important;
        }
    }

</style>